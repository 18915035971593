.tooltip {
    position: relative;
    /* display: inline-block; */
    cursor: help;
}

.tooltip-text {
    visibility: hidden;
    background-color: #092e52;
    color: #fff;
    text-align: center;
    padding: 4px 8px;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 10%;
    white-space: nowrap;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}