.tooltip {
  cursor: help;
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1;
  white-space: nowrap;
  background-color: #092e52;
  border-radius: 3px;
  padding: 4px 8px;
  position: absolute;
  bottom: 100%;
  left: 10%;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

/*# sourceMappingURL=index.5b7b9c3e.css.map */
